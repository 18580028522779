import React from 'react';
import { useState, useEffect } from 'react';
import './App.css';
import { Helmet } from 'react-helmet';
import { createGlobalState } from 'react-hooks-global-state';
import HeaderComponent from './Components/HeaderComponent';
import BodyComponent from './Components/BodyComponent';
import KontaktComponent from './Components/KontaktComponent';
import ONasComponent from './Components/ONasComponent';
import FooterComponent from './Components/FooterComponent';

const initialState = { currentWindow: "main", windowSize: {}, windowHeight: {} };
export const { useGlobalState } = createGlobalState(initialState);

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function App() {

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [sharedWindowDimensions, setSharedWindowDimensions] = useGlobalState("windowSize");
  const [sharedWindowDimensionsHeight, setSharedWindowDimensionsHeight] = useGlobalState("windowHeight");

  const [currentWindow, setCurrentWindow] = useGlobalState("currentWindow");
  let component;
  switch (currentWindow) {
    case "main":
      component = <BodyComponent />;
      break;
    case "oNas":
      component = <ONasComponent />;
      break;
    case "kontakt":
      component = <KontaktComponent />;
      break;
  }

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    setSharedWindowDimensions(windowDimensions.width);
    setSharedWindowDimensionsHeight(windowDimensions.height);
    console.log(sharedWindowDimensions);
    return () => window.removeEventListener('resize', handleResize);
  })


  return (
    <div className="App">
      <Helmet>
        <title>Luděk Osang s.r.o.</title>
        <meta name='Profesionální mezinárodní doprava'
          content='Profesionální mezinárodní dopravce. Zaměřujeme se nákladní dopravu lehkou až střední.' />
      </Helmet>
      <header className="App-header">
        <HeaderComponent />
      </header>
      <body>
        {component}
      </body>
      <footer>
        <FooterComponent />
      </footer>
    </div>
  );
}

export default App;
