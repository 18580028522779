import React, { useRef } from "react";
import * as framer from 'framer-motion';
import { FiTruck, FiStar, FiShield } from 'react-icons/fi';
import {Link} from 'react-scroll';
import '../Components/BodyComponent.css';
import { useGlobalState } from '../App';


export default function BodyComponent() {
    const [currentWindow, setCurrentWindow] = useGlobalState("currentWindow");

    const scrollDown = () => {
        const element = document.getElementById("AboutUsId");
        if(element){
            element.scrollIntoView({behavior: 'smooth'});
        }
    }

    return (
        <framer.motion.div className="Initial-div" initial={{opacity: 0}} whileInView={{opacity: 1}} transition={{delay: 0}}>
            <div className="Opacity-div">
                <div className="Svg-div">
                    <framer.motion.a className="Title-position">Luděk Osang s.r.o.</framer.motion.a>
                    <framer.motion.a className="SubTitle-position">S Vámi již od roku 1993</framer.motion.a>
                    <div className="Button-div">
                        <framer.motion.button className="ButtonSluzby" onClick={scrollDown} whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}><Link to="AboutUsId" spy={true} smooth={true} offset={50} duration={500}>Nabídka služeb</Link></framer.motion.button>
                        <framer.motion.button className="ButtonKontakt" whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }} onClick={() => {setCurrentWindow("kontakt")}}>Kontakt</framer.motion.button>
                    </div>
                </div>
            </div>
            <framer.motion.div className="Who-we-are-div" initial={{opacity: 0}} whileInView={{opacity: 1}} transition={{delay: 0.1}}>
                <framer.motion.div className="Left-side-div" id="AboutUsId">
                    <framer.motion.a className="Title-Left-side">Kdo jsme?</framer.motion.a>
                    <framer.motion.a className="Left-side-text">Jsme česká firma, zabývající se mezinárodní nákladní dopravou. Vznik se datuje na konec roku 1993, kdy začala fungovat jako sdružení dvou přátel. Nyní provozujeme v mezinárodní kusové dopravě osm plně vybavených vozidel Iveco Eurocargo 18 tun celkové hmotnosti zejména v relacích Benelux, Francie. Přepravujeme kusové zásilky, včetně nákladů podléhajících ADR.</framer.motion.a>
                </framer.motion.div>
                <framer.motion.div className="Right-side-div" initial={{opacity: 0}} whileInView={{opacity: 1}} transition={{delay: 0.1}}>
                    <framer.motion.div className="Right-helper-div">
                        <framer.motion.div className="Truck-div">
                            <FiTruck size={70} style={{ color: '#0078D4', alignSelf: 'center' }} />
                            <framer.motion.a>Moderní zázemí</framer.motion.a>
                        </framer.motion.div>
                        <framer.motion.div className="Star-div">
                            <FiStar size={70} style={{ color: '#0078D4', alignSelf: 'center' }} />
                            <framer.motion.a>Spokojenost zákazníků</framer.motion.a>
                        </framer.motion.div>
                    </framer.motion.div>
                    <framer.motion.div className="Shield-div">
                        <FiShield size={70} style={{ color: '#0078D4', alignSelf: 'center' }} />
                        <framer.motion.a>Integrita</framer.motion.a>
                    </framer.motion.div>
                </framer.motion.div>
            </framer.motion.div>
            <framer.motion.div className="Phone-contact-div" initial={{opacity: 0}} whileInView={{opacity: 1}} transition={{delay: 0.1}}>
                <framer.motion.a className="Phone-contact-title">Telefonní kontakt:</framer.motion.a>
                <framer.motion.a className="Phone-contact-text">+420 602 102 813</framer.motion.a>
            </framer.motion.div>
            <framer.motion.div className="Thanks-div" initial={{opacity: 0}} whileInView={{opacity: 1}} transition={{delay: 0.1}}>
                <framer.motion.a className="Thanks-text">Děkujeme, že v nás vkládáte důvěru.</framer.motion.a>
            </framer.motion.div>
        </framer.motion.div>
    )
}