import React from 'react';
import * as framer from 'framer-motion';
import '../Components/KontaktComponentCSS.css'

export default function KontaktComponent() {
    return (
        <framer.motion.div className='Initial-div' initial={{opacity: 0}} whileInView={{opacity: 1}}>
            <div style={{ backgroundColor: '#F5F5F5', minHeight: '84.4vh' }}>
                <framer.motion.div className='Nadpis-div'>
                    <framer.motion.a className='Title-Kontakt'>Kontakty</framer.motion.a>
                    <framer.motion.a className='Text-Kontakt-advance'>Telefon: +420 602 102 813</framer.motion.a>
                    <framer.motion.a className='Text-Kontakt-advance'><a className='Text-filler'>Telefon: </a>+420 724 213 593</framer.motion.a>
                    <framer.motion.a className='Text-Kontakt'><a className='Text-filler'>Telefon: </a> +420 724 241 850</framer.motion.a>
                    <framer.motion.a className='Text-Kontakt'>Email: luka.kt@seznam.cz</framer.motion.a>
                    <framer.motion.a className='Text-Kontakt'>Adresa: Kličkova 656 /2, 339 01 Klatovy</framer.motion.a>
                    <framer.motion.a className='Text-Kontakt-border'>Provozovna: Za Tratí 752, 339 01 Klatovy</framer.motion.a>
                    <framer.motion.a className='Text-Kontakt'>IČ: 07386541 </framer.motion.a>
                    <framer.motion.a className='Text-Kontakt'>DIČ (VAT no): CZ07386541 </framer.motion.a>
                    <framer.motion.a className='Text-Kontakt-border'>Zápis v OR u Krajského soudu Plzeň, Oddíl C, vložka 36735</framer.motion.a>
                    <framer.motion.a className='Text-Kontakt'>Číslo účtu (CZK): 5437034329/0800</framer.motion.a>
                    <framer.motion.a className='Text-Kontakt'><a className='Text-filler'>Číslo účtu (CZK): </a> 284746284/0300</framer.motion.a>
                    <framer.motion.a className='Text-Kontakt'>Číslo účtu (EU): 286105261/0300</framer.motion.a>
                    <framer.motion.a className='Text-Kontakt'>IBAN: CZ60 0300 0000 0002 8610 5261</framer.motion.a>
                    <framer.motion.a className='Text-Kontakt'>BIC: CEKOCZPP</framer.motion.a>

                </framer.motion.div>
                <div className='Map-div'><iframe className='MapFrame' frameBorder={0} scrolling="no" marginHeight={0} marginWidth={0} src="https://maps.google.com/maps?width=100%25&amp;height=800&amp;hl=en&amp;q=Za%20Trat%C3%AD%20752,%20339%2001%20Klatovy+(Lud%C4%9Bk%20Osang%20s.r.o.)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
                </iframe>
                </div>
            </div>

        </framer.motion.div>
    )
}