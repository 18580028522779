import React from "react";
import * as framer from 'framer-motion';
import '../Components/ONasComponentCSS.css';
import photoTwo from '../Images/photoTwo.jpg';
import photoOne from '../Images/photoOne.jpg';

export default function ONasComponent() {

    return (
        <framer.motion.div className="Initial-div" initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}>
            <div className="Usable-div">
                <framer.motion.a className="Title-text">Kdo jsme?</framer.motion.a>
                <framer.motion.a className="Text-column">Jsme česká  firma, zabývající se mezinárodní  nákladní dopravou. Vznik se datuje na konec roku 1993, kdy začala fungovat jako sdružení dvou přátel.
                    Již v počátcích bylo stanoveno, že budeme provozovat nákladní vozidla střední kategorie, v té době do 12tun celkové hmotnosti.
                    Zpočátku jsme se zabývali hlavně vývozem nábytku, ke kterému začala přibývat i přeprava kusového zboží v exportu i importu po celé Evropě.
                    To vyžadovalo i postupné rozšíření vozového parku, a tím i nabírání dalších zaměstnanců. Nechtěli jsme však překročit počet deseti vozidel, což jsme dodrželi dodnes. O spokojenosti našich kamarádů, které jsme zaměstnávali zpočátku a ostatních zaměstnanců svědčí i to, že někteří jsou u nás již přes dvacet let.
                    V roce 2018 jsme se tranformovali na společnost Luděk Osang s.r.o. Byl poměrně rychle obměněn vozový park, nyní vozidla 18tun celkové hmotnosti.
                    V současnosti provozujeme v mezinárodní kusové dopravě osm plně vybavených vozidel Iveco Eurocargo, zejména v relacích Benelux, Francie.</framer.motion.a>
                <framer.motion.a className="Title-smaller-text">Naše specializace:</framer.motion.a>
                <framer.motion.a className="Text-column">- Mezinárodní doprava zásilek</framer.motion.a>
                <framer.motion.a className="Text-column">- Maximální nosnost převozu zásilek o 23 paletách je 9t</framer.motion.a>
                <framer.motion.a className="Text-column">- Ložná plocha činí 9.2 ldm</framer.motion.a>
                <framer.motion.a className="Text-column">- Převoz zásilek podléhající ADR</framer.motion.a>
                <div className="Images-helper">
                    <div className="FirstImage-div">
                        <img src={photoTwo} className="First-image" />
                    </div>
                    <div className="SecondImage-div">
                        <img src={photoOne} className="Second-image" />
                    </div>
                </div>

            </div>
        </framer.motion.div>
    )
}