import React from 'react';
import * as framer from 'framer-motion';
import '../Components/FooterComponentCSS.css';

export default function FooterComponent(){
    return(
        <framer.motion.div className='Footer-initial-div'>
            <framer.motion.a className='Footer-text'>Luděk Osang s.r.o.</framer.motion.a>
            <framer.motion.a className='Footer-text'>Za Tratí 752, Klatovy</framer.motion.a>
        </framer.motion.div>
    )
}