import React from "react";
import * as framer from 'framer-motion';
import { useGlobalState } from '../App';
import { GiTruck } from 'react-icons/gi'
import '../Components/HeaderComponentCSS.css'


export default function HeaderComponent() {
    const [currentWindow, setCurrentWindow] = useGlobalState("currentWindow");

    return (
        <framer.motion.div className="Initial-div">
            <div className="Text-div">
                <framer.motion.div whileHover={{y: -3, x: -2}}>
                    <GiTruck size={55} className="Left-icon" onClick={() => {
                        setCurrentWindow("main")
                    }}/>
                </framer.motion.div>
                <framer.motion.a onClick={() => {
                    setCurrentWindow("main")
                }} className="Text-link" whileHover={{ scale: 1.1 }}>Úvod</framer.motion.a>
                <framer.motion.a onClick={() => {
                    setCurrentWindow("oNas")
                }} className="Text-link" whileHover={{ scale: 1.1 }}>O nás</framer.motion.a>
                <framer.motion.a onClick={() => {
                    setCurrentWindow("kontakt")
                }} className="Text-link" whileHover={{ scale: 1.1 }}>Kontakty</framer.motion.a>

            </div>
        </framer.motion.div>
    )
}